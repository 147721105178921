.header {
  width: 100%;
  position: absolute;
  background-color: transparent;
  display: flex;
  justify-content: center;
}

.navbar {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  z-index: 5;
}

.navLogo {
  width: 100%;
  justify-content: flex-start;
  display: flex;
}

.logoImg {
  width: 100%;
  max-width: 200px;
  cursor: pointer;
  margin-top: 10px;
}

.burgerimg {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.burgerIcon {
  width: 70px;
  cursor: pointer;
}

.btnBurger {
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  border: none;
}

.navMenu {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: black;
  top: -80%;
  left: 80%;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s;
}
.navMenu ul {
  list-style: none;
  padding: 0;
}
.navMenu ul li {
  text-align: center;
}
.navMenu ul li a:hover {
  font-size: 50px;
}
.ListBtn {
  background-color: transparent;
  border: none;
  text-decoration: none;
  line-height: 1.2;
  font-size: 30px;
  font-weight: 800;
  font-family: 'DM Sans', sans-serif;
  color: #fff;
  transition: 0.4s;
  text-transform: uppercase;
  cursor: pointer;
  padding: 5px;
}
.ListBtn:hover {
  font-size: 35px;
}

@media only screen and (max-width: 600px) {
  .ListBtn {
    font-size: 20px;
    line-height: 1;
    padding: 0px 5px;
  }
  .navMenu ul {
    padding: 0;
  }
  .burgerIcon {
    width: 57px;
    cursor: pointer;
  }
  .ListBtn:hover {
    font-size: 25px;
  }
}
