.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  scroll-snap-align: start;
}

.footercontents {
  width: 100%;
  max-width: 1360px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
}

.footerTitle {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  padding: 0px 10px;
}
.footerTitle p {
  font-size: 18px;
  font-weight: 500;
  font-family: 'DM Sans', sans-serif;
}
.socialImg {
  width: 30%;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px;
}
.socialImg a {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.inLogo {
  width: 40px;
}
