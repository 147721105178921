.heropop {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 15;
  position: fixed;
  top: 0;
  display: flex;
  align-content: center;
  justify-content: center;
}

.heropop video {
  width: 80%;
  height: 80%;
  position: absolute;
  object-fit: cover;
  top: 10%;
}

.popupclose {
  width: 100%;
  height: 100%;
  z-index: 20;
}

.popupbutton {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0;
  cursor: none;
}

.pauseimg {
  position: absolute;
  width: 20px;
  height: 20px;
}

.password {
  display: flex;
  align-items: center;
  justify-content: center;
}

.password p {
  font-size: 25px;
  font-weight: 600;
  font-family: 'DM Sans', sans-serif;
  color: white;
}

.passtxt {
  display: flex;
  flex-direction: column;
}

.input {
  font-family: 'DM Sans', sans-serif;
  padding: 8px 5px 8px 3px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input button {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  background: rgba(34, 34, 34, 0.5);
  color: white;
  border: none;
  box-shadow: none;
  border-radius: 26px;
  padding: 8px 15px;
  cursor: pointer;
}

.input input {
  border: none;
  padding: 5px 10px;
  color: #333333;
  width: 220px;
}

.inputfield:focus {
  border: none;
}

.inputfield:focus-visible {
  outline: none;
}

.backbtn {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.backbtn button {
  color: white;
  font-family: 'DM Sans', sans-serif;
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
}
