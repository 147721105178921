.container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.section {
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
  position: relative;
  transition: all 1.5s;
}

.hero {
  width: 100%;
  height: calc(100vh - 110px) !important;
  scroll-snap-align: start;
  background-color: #09222f;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 15px;
}

.herocontent {
  width: 100%;
  max-width: 1360px;
  height: 63%;
  display: grid;
}
.herotitle {
  width: 100%;
}

.herotitle p {
  font-size: 43px;
  font-weight: 600;
  font-family: 'DM Sans', sans-serif;
  color: #fff;
  line-height: 1.3;
  padding-right: 30px;
}
.herobottom {
  display: grid;
  align-items: center;
}
.buttons {
  display: block;
}
.sectionBtn {
  display: flex;
  width: 100%;
  background-color: transparent;
  border: none;
  margin: 15px 0;
  justify-content: flex-start;
  align-items: center;
}
.textBtn {
  width: 60%;
  font-size: 29px;
  font-weight: 600;
  font-family: 'DM Sans', sans-serif;
  color: #168a8c;
  text-align: left;
  padding: 0 20px;
  cursor: pointer;
}

.imgArrow {
  width: 8%;
  cursor: pointer;
}
.imgArrow:hover {
  width: 9%;
  transition: all 0.8s;
}

.videosContent {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: -1;
}
.sectionVidContent {
  position: absolute;
  bottom: 80px;
  left: 50%;
  width: 100%;
  max-width: 1360px;
  margin: auto;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  padding: 0 10px;
}
.sectionVidContent p,
button {
  margin: 15px 0;
}
.sectionTitle {
  font-size: 45px;
  font-weight: 600;
  cursor: pointer;
}
.sectionDiscription,
.sectionTitle {
  font-family: 'DM Sans', sans-serif;
  color: #fff;
  line-height: 1.1;
  padding: 0px 20px;
}
.sectionDiscription {
  font-size: 30px;
  font-weight: 300;
  font-style: italic;
}
.sectionArrow {
  background-color: transparent;
  border: none;
  display: flex;
  padding: 4px 20px 0px;
}
.imgArrowSec {
  width: 23%;
  cursor: pointer;
  min-width: 45px;
}
@media only screen and (max-width: 1045px) {
  .brRemove {
    display: none;
  }
}

@media only screen and (max-width: 820px) {
  .herotitle p {
    font-size: 35px;
    padding-right: 30px;
  }
  .textBtn {
    font-size: 20px;
    width: 100%;
  }
  .logoImg {
    margin-top: 0px;
  }
  .sectionTitle {
    font-size: 35px;
  }
  .sectionDiscription {
    font-size: 28px;
  }
}

@media only screen and (max-width: 600px) {
  .section {
    height: 50vh;
  }
  .brRemove {
    display: none;
  }
  .herotitle p {
    font-size: 28px;
    padding-right: 30px;
  }
  .hero {
    height: 75vh !important;
  }
  .textBtn {
    font-size: 18px;
    width: 100%;
  }
  .sectionTitle {
    font-size: 20px;
  }
  .sectionDiscription {
    font-size: 16px;
  }
  .sectionVidContent {
    bottom: 10px;
  }
  .sectionVidContent p,
  button {
    margin: 5px 0;
  }
}

@media only screen and (max-width: 400px) {
  .herotitle p {
    font-size: 25px;
    padding-right: 30px;
  }

  .sectionTitle {
    font-size: 22px;
  }
  .sectionDiscription {
    font-size: 16px;
  }
}
