.detailcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detailinner {
  width: 1300px;
}

.detaillogo {
  display: flex;

  padding: 10px 15px;
  width: 77px;
}
.row {
  width: 100%;
  display: flex;
}

.col {
  width: 50%;
  padding: 20px;
}

.image {
  position: relative;
  width: 100%;
}

.image img {
  width: 100%;
  vertical-align: top;
}

.image:before {
  content: '\A';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.image:hover:before {
  opacity: 1;
}

.innertext {
  width: 90%;
  height: 100%;
  position: absolute;
  opacity: 0;
  top: 5%;
  left: 25px;
}

.innertext p {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 0px 0% 15px;
  text-align: left;
}

.innertext h2 {
  color: white;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  padding: 0px 0% 15px;
  text-align: left;
}
.innertext:hover {
  opacity: 1;
}
